// in src/posts.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  SimpleShowLayout,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  required,
  SelectInput,
  BooleanInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  SimpleForm,
  email,
  Toolbar,
    SaveButton,
    DeleteWithConfirmButton,
    TopToolbar,
    BooleanField,
    useCreate

} from "react-admin";
import { CompactForm, RaBox } from 'ra-compact-ui';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
  toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
  },
});
const HourValues =  [
  { id: '00:00', name: '00:00 AM' },
  { id: '01:00', name: '01:00 AM' },
  { id: '02:00', name: '02:00 AM' },
  { id: '03:00', name: '03:00 AM' },
  { id: '04:00', name: '04:00 AM' },
  { id: '05:00', name: '05:00 AM' },
  { id: '05:30', name: '05:30 AM' },
  { id: '06:00', name: '06:00 AM' },
  { id: '07:00', name: '07:00 AM' },
  { id: '08:00', name: '08:00 AM' },
  { id: '08:30', name: '08:30 AM' },
  { id: '09:00', name: '09:00 AM' },
  { id: '10:00', name: '10:00 AM' },
  { id: '11:00', name: '11:00 AM' },
  { id: '12:00', name: '12:00 PM' },
  { id: '13:00', name: '01:00 PM' },
  { id: '14:00', name: '02:00 PM' },
  { id: '15:00', name: '03:00 PM' },
  { id: '16:00', name: '04:00 PM' },
  { id: '17:00', name: '05:00 PM' },
  { id: '17:30', name: '05:30 PM' },
  { id: '18:00', name: '06:00 PM' },
  { id: '19:00', name: '07:00 PM' },
  { id: '20:00', name: '08:00 PM' },
  { id: '20:30', name: '08:30 PM' },
  { id: '21:00', name: '09:00 PM' },
  { id: '22:00', name: '10:00 PM' },
  { id: '23:00', name: '11:00 PM' },
];

const filters = [
        <TextInput source = "employee_id" label="ID" alwaysOn/>,
        <TextInput source = "first_name" label="First Name" />,
        <BooleanInput source = "active" label="Active" alwaysOn/>,
        <SelectInput label="Role" source="role" choices={[
          { id: 'employee', name: 'Employee' },
          { id: 'driver', name: 'Driver' },
          { id: 'admin', name: 'Admin' },
          { id: 'superadmin', name: 'Super Admin' },
        ]} alwaysOn/>,
        <TextInput source="company"/>,
        <TextInput source="position_code" />
      
];

const SaveToolbar = (props) => (

  <Toolbar {...props} classes={useStyles()}>
    {(props.record.role==="driver" || props.record.role === "employee") &&
      <SaveButton />
}
  </Toolbar>
);

const UserDeviceEdit = (props) => (
  <Edit {...props} >
    <SimpleForm toolbar={<SaveToolbar />}>
      {/* {(props.record.role === "driver" || props.record.role === "employee") &&
        <TextInput source="device_id" label="Reset Device? (Clear this field and save to reset)" fullWidth={true} />} */}
        <RaBox display="flex" justifyContent="space-between" fullWidth={true}>
          <SelectInput source="shift_starts_at" choices={HourValues} />
          <SelectInput source="shift_ends_at" choices={HourValues} />
          <BooleanInput source="is_overnight_shift" />
        </RaBox>
      {(props.record.role === "driver") &&
        <TextInput source="assigned_employee_id" label="Assigned Employee for Driver" />}
      {(props.record.role === "employee") &&
        <ArrayInput source="managers" label="Line Managers">
          <SimpleFormIterator>
            <TextInput source="" type="email" validate={[email("Invalid email!"),required()]} />
          </SimpleFormIterator>
        </ArrayInput>
      }
    </SimpleForm>
  </Edit>
);

export const UserList = ({permissions, ...props}) => (
  <List {...props} filters={filters} bulkActionButtons={false} filterDefaultValues={{ active: true, role: 'admin' }}> 
    <Datagrid expand={<UserDeviceEdit />} rowClick="show">
    <TextField source="employee_id"/>
    <TextField source="first_name"/>
      <TextField source="last_name" />
      <TextField source="email" />
      <TextField source="role" />
      <TextField source="assigned_employee_id" />
    </Datagrid>
  </List>
);


function populateList(numbers) {
  if (numbers == null) return <div></div>;
  return numbers.map((number) =>
      <li key={number.toString()}>
          {number}
      </li>
  );
}

const SimpleArray = ({source, record = {}}) =>
  <ul>
      {
          populateList(record[source])
      }
  </ul>;


SimpleArray.defaultProps = {
  addLabel: true,
  label: 'List'
};

const UserShowActions = ({ permissions, ...props }) => (
  <TopToolbar>
      {permissions.role === "superadmin" &&
      <EditButton basePath={props.basePath} record={props.data} />
      }
  </TopToolbar>
);

export const UserShow = ({ permissions, ...props }) => (
  <Show {...props} actions={<UserShowActions permissions={permissions}/>}>
    <SimpleShowLayout>
      <TextField source="employee_id" />
      <TextField source="company" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="email" />
      <TextField source="position_code" />
      <TextField source="role" />
      <BooleanField source="active" />
      <TextField source="assigned_employee_id" />
      <SimpleArray source="managers" label="Managers"/>
    </SimpleShowLayout>
  </Show>
);

const transform = data => {
  let transformed = {
    ...data
  };
  if (transformed.role === "driver" || transformed.role === "employee") {
    transformed.email = "eztime@test.com";
  }
  transformed.email = transformed.email.trim();
  if (transformed.employee_id != null) {
    transformed.id = transformed.employee_id;
  }
  transformed.active = true;
  return transformed;
};


export const UserCreate = ({permissions, ...props}) => (
  <Create {...props} transform={transform} redirect="show" >
    <SimpleForm layoutComponents={[RaBox]} >
      {permissions.role === "admin" &&
    <SelectInput label="Select Role" source="role" choices={[
    { id: 'employee', name: 'Employee' },
    { id: 'driver', name: 'Driver' },
  ]} />
}
{permissions.role === "superadmin" &&
    <SelectInput label="Select Role" source="role" choices={[
    { id: 'employee', name: 'Employee' },
    { id: 'driver', name: 'Driver' },
    { id: 'admin', name: 'Admin' },
    { id: 'superadmin', name: 'Super Admin' },
  ]} />
}
  <FormDataConsumer>
    {({ formData, ...rest }) => (formData.role === "admin" || formData.role === "superadmin") && <div>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput source="first_name" validate={[required()]}/>
    </RaBox>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput source="last_name" validate={[required()]}/>
    </RaBox>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput label="Email" source="email" type="email" validate={[required(), email("Invalid email!")]}/>
    </RaBox>
    </div>}
    </FormDataConsumer>
    <FormDataConsumer>
    {({ formData, ...rest }) => (formData.role === "driver") && <div>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput label="Employee ID" source="employee_id" validate={[required()]}/>
    <TextInput source="first_name" validate={[required()]}/>
    <TextInput source="last_name" validate={[required()]}/>
    </RaBox>
    <TextInput source="cab_operators" />
    <h3>Shift Details</h3>
    <RaBox display="flex" flex="0 0 100%" justifyContent="space-between">
    <SelectInput source="shift_starts_at" choices={HourValues}/>
    <SelectInput source="shift_ends_at" choices={HourValues}/>
    <BooleanInput source="is_overnight_shift"/>
    </RaBox>
    <TextInput source="assigned_employee_id" />
    </div>}
    </FormDataConsumer>
    <FormDataConsumer>
    {({ formData, ...rest }) => (formData.role === "employee") && <div>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput label="Employee ID" source="employee_id" validate={[required()]}/>
    <TextInput source="first_name" validate={[required()]}/>
    <TextInput source="last_name" validate={[required()]}/>
    </RaBox>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput label="Position Code" source="position_code" validate={[required()]}/>
    <TextInput source="company" validate={[required()]}/>
    <TextInput source="department"/>
    </RaBox>
    {/* <TextInput label="Line Managers ID" source="lm_id" validate={[required()]}/>
    <TextInput label="Team Leaders ID" source="tl_id" defaultValue={["20001"]} validate={[required()]}/>
    </RaBox> */}
    <h3>Shift Details</h3>
    <RaBox display="flex" flex="0 0 100%" justifyContent="space-between">
    <SelectInput source="shift_starts_at" choices={HourValues}/>
    <SelectInput source="shift_ends_at" choices={HourValues}/>
    <BooleanInput source="is_overnight_shift" />
    <BooleanInput source="is_metros_staff"  defaultValue={false}/>
    </RaBox>
    <ArrayInput source="managers" label="Line Managers">
    <SimpleFormIterator>
    <TextInput source="" type="email" validate={[email("Invalid email!")]}/>
    </SimpleFormIterator>
    </ArrayInput>
    </div>}
    </FormDataConsumer>
    </SimpleForm>
  </Create>
);

const UserTitle = ({ record }) => {
  return <span>{record ? `${record.role.toUpperCase() + ": " + record.id}` : ''}</span>;
};

const CustomToolbar = ({redirect,...props}) => (
  <Toolbar {...props} classes={useStyles()}>
      <SaveButton submitOnEnter={false} redirect={redirect} {...props} />
  </Toolbar>
);

// const deleteAction = (data) => {
//   const [create, { isLoading, error }] = useCreate();
//   create('deleted_users', { data: data });

// };

const UserEditActions = ({ permissions, ...props }) => (
  <TopToolbar>
      <ShowButton basePath={props.basePath} record={props.data} />
      {permissions.role === "superadmin" &&
      <DeleteWithConfirmButton confirmContent="You will not be able to recover this record. Are you sure?"  basePath={props.basePath} record={props.data} />}
  </TopToolbar>
  );

export const UserEdit = ({permissions, ...props}) => (
<Edit title={<UserTitle/>} {...props} actions={<UserEditActions permissions={permissions}/>} >
 <SimpleForm toolbar={<CustomToolbar />}>
  <FormDataConsumer>
    {({ formData, ...rest }) => (formData.role === "admin" || formData.role === "superadmin") && <div>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput source="first_name" validate={[required()]}/>
    </RaBox>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput source="last_name" validate={[required()]}/>
    </RaBox>
    <BooleanInput source="active" fullWidth={false} row={true}/>
    </div>}
    </FormDataConsumer>
    <FormDataConsumer>
    {({ formData, ...rest }) => (formData.role === "driver") && <div>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput source="first_name" validate={[required()]}/>
    <TextInput source="last_name" validate={[required()]}/>
    </RaBox>
    <BooleanInput source="active" fullWidth={false} row={true}/>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput source="device_id"/>
    <TextInput source="cab_operators" />
    </RaBox>
    <h3>Shift Details</h3>
    <RaBox display="flex" flex="0 0 100%" justifyContent="space-between">
    <SelectInput source="shift_starts_at" choices={HourValues}/>
    <SelectInput source="shift_ends_at" choices={HourValues}/>
    <BooleanInput source="is_overnight_shift"/>
    </RaBox>
    <TextInput source="assigned_employee_id" />
    </div>}
    </FormDataConsumer>
    <FormDataConsumer>
    {({ formData, ...rest }) => (formData.role === "employee") && <div>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput source="first_name" validate={[required()]}/>
    <TextInput source="last_name" validate={[required()]}/>
    <TextInput source="company" validate={[required()]}/>
    </RaBox>
    <RaBox display="flex" justifyContent="space-between">
    <TextInput source="device_id"/>
    <TextInput label="Position Code" source="position_code" validate={[required()]}/>
    <TextInput source="department"/>
    </RaBox>
    <BooleanInput source="active" fullWidth={false} row={true}/>
    {/* <RaBox display="flex" justifyContent="space-between">
    <TextInput label="Line Managers ID" source="lm_id" validate={[required()]}/>
    <TextInput label="Team Leaders ID" source="tl_id" defaultValue={["20001"]} validate={[required()]}/>
    </RaBox> */}
    <h3>Shift Details</h3>
    <RaBox display="flex" flex="0 0 100%" justifyContent="space-between">
    <SelectInput source="shift_starts_at" choices={HourValues}/>
    <SelectInput source="shift_ends_at" choices={HourValues}/>
    <BooleanInput source="is_overnight_shift" />
    <BooleanInput source="is_metros_staff" defaultValue={false}/>
    </RaBox>
    <ArrayInput source="managers">
    <SimpleFormIterator>
    <TextInput source="" type="email" validate={[email("Invalid email!"),required()]}/>
    </SimpleFormIterator>
    </ArrayInput>
    </div>}
    </FormDataConsumer>
    </SimpleForm></Edit>
);