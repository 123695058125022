// FIREBASE_CONFIG.js
export const firebaseConfig = {
    apiKey: "AIzaSyBURKaqhKh0ReJoFfON8GN0Rs33tEEpMnw",
    authDomain: "eztime-app.firebaseapp.com",
    databaseURL: "https://eztime-app-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "eztime-app",
    storageBucket: "eztime-app.appspot.com",
    messagingSenderId: "756627937352",
    appId: "1:756627937352:web:b486927ed21492cf6ae07d",
    measurementId: "G-SGZQ8SYXCC"
  };
