import { Datagrid, DateField, FileField, FunctionField, ImageField, List, Show, SimpleShowLayout, TextField } from "react-admin";

export const TaskList = ({permissions, ...props}) => (
    <List {...props} hasCreate={false} bulkActionButtons={false}>
        <Datagrid rowClick="show">
            <TextField label="Title" source="title"/>
            <TextField label="Location" source="location"/>
            <TextField label="Assigned By" source="assigned_by_name"/>
            <FunctionField label="Assigned To" render={r => r.assigned_to_name || r.assigned_to_id}/>
            {/* <TextField label="Assigned To" source="assigned_to_name"/> */}
            <DateField source="tentative_start_time" showTime locales='en-IN'/>
            <DateField source="tentative_end_time" showTime locales='en-IN'/>
            <DateField source="actual_start_time" showTime locales='en-IN'/>
            <DateField source="actual_end_time" showTime locales='en-IN'/>
            <TextField label="Remarks" source="remarks"/>
        </Datagrid>
    </List>
);

export const TaskShow = ({permissions,...props}) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField label="Title" source="title"/>
            <TextField label="Description" source="description"/>
            <TextField label="Location" source="location"/>
            <TextField label="Assigned By" source="assigned_by_name"/>
            <FunctionField label="Assigned To" render={r => r.assigned_to_name || r.assigned_to_id}/>
            {/* <TextField label="Assigned To" source="assigned_to_name"/> */}
            <DateField source="tentative_start_time" showTime locales='en-IN'/>
            <DateField source="tentative_end_time" showTime locales='en-IN'/>
            <DateField source="actual_start_time" showTime locales='en-IN'/>
            <DateField source="actual_end_time" showTime locales='en-IN'/>
            <ImageField source="photo_urls" src="url" title="title"/>
            <FileField source="doc_urls" src="url" title="title" />
            <TextField label="Remarks" source="remarks"/>
        </SimpleShowLayout>
    </Show>
);