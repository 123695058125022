import * as React from "react";

import { List, Datagrid, Show, Create, SimpleForm, DateField, TextField, SimpleShowLayout, TextInput, SelectInput, BooleanField, BooleanInput, DeleteWithConfirmButton, DateTimeInput, TopToolbar } from 'react-admin';

export const ReportList = ({permissions, ...props}) => (
    <List {...props} hasCreate={permissions.role === "superadmin"} bulkActionButtons={false}>
        <Datagrid rowClick="show">
            <TextField source="title" />
            <DateField source="start_date" locales='en-IN' />
            <DateField source="end_date" locales='en-IN' />
            <BooleanField source="only_admins" defaultValue='false' />
            <BooleanField source="success" />
            <TextField source="error_message" />
        </Datagrid>
    </List>
);

export const ReportCreate = (props) => (
    <Create title="Create a report" {...props}>
        <SimpleForm>
            <TextInput source="title" />
            <DateTimeInput source="start_date" />
            <DateTimeInput source="end_date"/>
            <BooleanInput source="only_admins" />
            <SelectInput label="Select Type" source="type" choices={[
    { id: 'weekly', name: 'Weekly' },
    { id: 'monthly', name: 'Monthly' },
  ]} />

        </SimpleForm>
    </Create>
);

function populateList(numbers) {
    if (numbers == null || numbers.length == 0) return <div></div>;
    return numbers.map((number) =>
        <li key={number.toString()}>
            {number.split("-")[0]} - <a href={number.split('-').slice(1).join('-')}>View Report</a>
        </li>
    );
}

const SimpleArray = ({source, record = {}}) => { 
    const src = source.split("-");
    let rec = new Array();
    if(record.type == "weekly") {    
    record[src[0]].forEach((t) => {
        if(t.endsWith(src[1])) rec.push(t);
    });
    } else {
        rec = record[src[0]];
    }
    return <ul>
        {
           !(src[1] == "tasks" && record.type == "monthly") && populateList(rec)
        }
    </ul>;
}


SimpleArray.defaultProps = {
    addLabel: true,
    label: 'List'
 };


 const PostShowActions = ({ permissions, basePath, data, resource }) => (
    <TopToolbar>
        {permissions.role === 'superadmin' &&
             <DeleteWithConfirmButton confirmContent="You will not be able to recover this record. Are you sure?" basePath={basePath} record={data} />
        }
    </TopToolbar>
  );

export const ReportShow = ({permissions, ...props}) => (
    <Show actions={<PostShowActions permissions={permissions} />} {...props}>
    <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="title" />
            <TextField source="type" />
            <DateField source="start_date" locales='en-IN' />
            <DateField source="end_date" locales='en-IN' />
            <BooleanField source="only_admins"/>
            <BooleanField source="success" />
            <SimpleArray source="links-all" label="Attendance Links" />
            <SimpleArray source="links-tasks" label="Task Links" />
            <TextField source="error_message" />
        </SimpleShowLayout>
    </Show>
);